<template>
  <div v-if="isShow">
    <div v-on-clickaway="hide">
      <div>
        <div class="relative hidden sm:block">
          <base-input-text
            v-model="q"
            data-testid="text-field-search"
            :loading="loading"
            icon-fa="search"
            :placeholder="$t('nav.search')"
            type="text"
            class="border-0 input-pr-8"
            @keyup="qChange"
            @click="inputClick"
          />
          <div class="icon--clear" @click="clearClick">
            <base-icon v-if="q" data-testid="icon-close" size="14" name="close">
              <icon-close />
            </base-icon>
          </div>
        </div>
        <div class="block sm:hidden">
          <div class="cursor-pointer p-2" @click="toggleSearch">
            <base-icon
              v-if="!isShowSearch"
              color="white"
              :width="18"
              :height="18"
              name="search"
            >
              <icon-search />
            </base-icon>
            <base-icon
              v-else
              :width="18"
              :height="18"
              color="white"
              name="close-search"
            >
              <icon-close />
            </base-icon>
          </div>
          <div
            v-if="isShowSearch"
            class="py-3 px-10 bg-brand-blue h-16 absolute top-0 left-0 w-full mt-16"
          >
            <div class="relative">
              <base-input-text
                v-model="q"
                :loading="loading"
                :placeholder="$t('nav.search')"
                icon-fa="search"
                type="text"
                class="input-pr-8"
                @keyup="qChange"
                @click="inputClick"
              />
              <div class="icon--clear" @click="clearClick">
                <base-icon v-if="q" size="14" name="close">
                  <icon-close />
                </base-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <base-dropdown
        v-if="qTasks.length || qBoards.length"
        ref="qDropdown"
        data-testid="dropdown-search"
        position="bottom-right"
        width="xlarge"
        max-height="360"
        :class="{ 'mt-40-a': isShowSearch }"
        @hide="q = ''"
      >
        <div slot="toggle" />
        <base-dropdown-item
          v-if="qTasks.length"
          keep
          :hoverable="false"
          class="text-sm text-gray-600"
          >{{ $t('search.task') }}</base-dropdown-item
        >
        <base-dropdown-item v-for="(t, i) in qTasks" :key="`q-task-${i}`">
          <div class="flex py-1" @click="gotoTask(t)">
            <div class="flex-1">
              <div class="text-xs text-gray-caption">{{ t.code }}</div>
              <div class="text-gray-800 text-2-line">{{ t.name }}</div>
              <div class="text-gray-700 text-xs font-thin truncate">
                {{ $t('search.board') }}: {{ t.board ? t.board.name : '' }}
              </div>
            </div>
          </div>
        </base-dropdown-item>
        <base-dropdown-item
          v-if="qBoards.length"
          keep
          :hoverable="false"
          class="text-sm text-gray-600 border-gray-200"
          :class="{ 'border-t mt-2': qTasks.length }"
          >{{ $t('search.boards') }}</base-dropdown-item
        >
        <base-dropdown-item
          v-for="(b, i) in qBoards"
          :key="`q-broad-${i}`"
          class="flex"
        >
          <div class="flex-1 text-2-line text-gray-800" @click="gotoBoard(b)">
            <div class>
              {{ b.name }}
              <base-icon
                v-if="b.isStarred"
                name="star"
                :width="20"
                class="text-star-yellow ml-1 mt--1"
              >
                <icon-star />
              </base-icon>
            </div>
          </div>
        </base-dropdown-item>
      </base-dropdown>
      <base-dropdown
        v-else
        ref="qDropdown"
        position="bottom-right"
        width="xlarge"
        :class="{ 'mt-40-a': isShowSearch }"
      >
        <div slot="toggle" />
        <div class="flex justify-center align-middle">
          <div class="px-4 py-8">
            <div class="flex justify-center">
              <logo-task-search-not-found />
            </div>

            <div>
              <div class="mt-4 mb-4 text-center text-2xl">
                {{ $t('search.nofound') }}
              </div>
              <div class="text-center text-sm text-gray-600">
                {{ $t('search.keywords') }}
              </div>
            </div>
          </div>
        </div>
      </base-dropdown>
    </div>
  </div>
</template>

<script>
import IconStar from '@/components/icons/IconStar';
import IconClose from '@/components/icons/IconClose.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
import { boardsApi } from '@/services/apis';
import LogoTaskSearchNotFound from '@/components/icons/LogoTaskSearchNotFound';
import { directive as onClickaway } from 'vue-clickaway';

export default {
  components: { IconStar, IconClose, IconSearch, LogoTaskSearchNotFound },
  data() {
    return {
      loading: false,
      q: '',
      filter: {},
      qTasks: [],
      qBoards: []
    };
  },
  watch: {},
  directives: {
    onClickaway
  },
  methods: {
    async qChange() {
      if (!this.q) {
        this.$refs.qDropdown.hide();
        return;
      }

      this.loading = true;
      this.filter = {
        q: this.q
      };
      const res = await boardsApi.search(this.filter);
      this.qTasks = res.data.tasks ? res.data.tasks : [];
      this.qBoards = res.data.boards ? res.data.boards : [];

      this.loading = false;
      this.$refs.qDropdown.show();
    },
    clearClick() {
      this.q = '';
      this.qChange();
    },
    inputClick() {
      if (this.filter.q) {
        this.$refs.qDropdown.show();
      }
    },
    hide() {
      this.q = '';
      this.$store.commit('notifications/SET_IS_SHOW_SEARCH', false);
    },
    gotoTask(t) {
      this.$router.push({
        name: 'Task-Open',
        params: { id: t.id.toString() }
      });
      this.$refs.qDropdown.hide();
    },
    gotoBoard(board) {
      const boardRouteNames = [
        'Board-Normal',
        'Board-Waterfall',
        'Board-Agile'
      ];
      this.$router.push({
        name: boardRouteNames[board.type],
        params: {
          id: board.id
        }
      });
      this.$refs.qDropdown.hide();
    },
    toggleSearch() {
      this.$store.commit(
        'notifications/SET_IS_SHOW_SEARCH',
        !this.isShowSearch
      );
    }
  },
  computed: {
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    profileImg() {
      return (
        this.profile.profilePicture ||
        'https://www.pngarts.com/files/5/User-Avatar-PNG-Image.png'
      );
    },
    isShow() {
      return this.$route.name != 'My-Tasks';
    },
    isShowSearch() {
      return this.$store.getters['notifications/getIsShowSearch'];
    }
  }
};
</script>

<style>
.mt-40-a {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 7.4rem;
  width: 100%;
}
.mt-40-a .base-dropdown__content {
  left: 0;
  right: 0;
  width: 100%;
  max-width: calc(100% - 5rem) !important;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.4) 5px 100px 100px;
}

.icon--clear {
  @apply px-3 absolute right-0 inset-y-0 cursor-pointer z-10 inline-flex place-items-center;
}
</style>
