

























import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    lines: {
      type: Number as PropType<number>,
      default: 2
    }
  },
  data() {
    return {
      active: false,
      isOver: false
    };
  },
  computed: {
    p(): HTMLParagraphElement {
      return this.$refs.paragraph as HTMLParagraphElement;
    },
    height(): string {
      const aLineHeight = 21;
      return `${this.lines * aLineHeight}px`;
    }
  },
  mounted() {
    this.handleShowButton();
  },
  methods: {
    handleShowButton() {
      this.isOver = this.p.offsetHeight < this.p.scrollHeight;
    }
  }
});
