<template>
  <base-dropdown class="inline-flex w-full md:w-auto">
    <base-button
      slot="toggle"
      data-testid="create-btn"
      color="white"
      class="px-0 h-full py-2 w-full md:w-auto"
      icon="Plus"
      icon-size="18"
    >
      {{ $t('create.create') }}
    </base-button>

    <div slot="header" class="text-lg font-medium">
      {{ $t('create.create') }}
    </div>
    <base-dropdown-item
      data-testid="create-board-btn"
      class="mt-1"
      @click="openModal('create-board')"
    >
      <div class="dropdown__content">
        <base-icon size="18" class="icon__menu">
          <icon-create-board /> </base-icon
        >{{ $t('create.board') }}
      </div>
    </base-dropdown-item>
    <base-dropdown-item
      data-testid="create-folder-btn"
      @click.native="openModal('form-folder')"
    >
      <div class="dropdown__content">
        <base-icon size="18" class="icon__menu">
          <icon-create-folder /> </base-icon
        >{{ $t('create.folder') }}
      </div>
    </base-dropdown-item>
    <base-dropdown-item
      data-testid="create-team-btn"
      @click.native="openModal('create-workspace')"
    >
      <div class="dropdown__content">
        <base-icon size="18" class="icon__menu"> <icon-workspace /> </base-icon
        >{{ $t('create.workspace') }}
        <div v-if="canFreeTrial" class="chip-beta">
          <span>{{ $t('free_plan') }} </span>
        </div>
      </div>
    </base-dropdown-item>
  </base-dropdown>
</template>

<script>
import IconWorkspace from '@/components/icons/IconWorkspace';
import IconCreateBoard from '@/components/icons/IconCreateBoard';
import IconCreateFolder from '@/components/icons/IconCreateFolder';

export default {
  computed: {
    isHaveSubscription() {
      return (
        this.$store.getters['auth/getUser'].haveActiveSubscription ||
        this.$store.getters['auth/getUser'].subscriptionExempted
      );
    },
    canFreeTrial() {
      return this.$store.getters['auth/getUser'].canFreeTrial;
    }
  },
  components: {
    IconWorkspace,
    IconCreateBoard,
    IconCreateFolder
  },
  methods: {
    openModal(modal) {
      let modalName;
      if (modal === 'create-workspace') {
        modalName = this.isHaveSubscription ? modal : 'plan-card';
      } else {
        modalName = modal;
      }
      this.$modal.show(modalName);
    }
  }
};
</script>

<style scoped>
.dropdown__content {
  @apply py-1 font-medium flex;
}
.dropdown__content .icon__menu {
  @apply self-center mr-1;
  padding-top: 2px;
}
.chip-beta {
  @apply text-xs ml-2 self-center;
  border: 1px solid #168ae2;
  color: #168ae2;
  border-radius: 28px;
  padding: 0px 8px;
}
</style>
<i18n>
{
  "en": {
    "free_plan": "Free Trial",
    "description": "Activate your 90 days free trial for your Workspace"
  },
  "th": {
    "free_plan": "ทดลองใช้ฟรี",
    "description": "ทดลองใช้งาน เวิร์คสเปซ เต็มรูปแบบฟรีนาน 90 วัน"
  }
}
</i18n>
