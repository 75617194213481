<template>
  <div class="sticky top-0 left-0 z-40">
    <div
      v-if="isAlertSubscription"
      class="sticky top-0 left-0 z-40 flex items-center justify-center"
      style="height: 40px;background: #FAF3C0;"
    >
      {{ $tc('navbar.title_alert_subscription', daysRemaining) }}

      <span class="subscription-alert-btn" @click="renewPlan()">{{
        $t('navbar.renew_subscription')
      }}</span>
      <div class="subscription-dot" />
      <span class="subscription-alert-btn" @click="closeAlertSubscription()">{{
        $t('navbar.cancel_subscription')
      }}</span>
    </div>
    <!-- desktop -->
    <header
      class="hidden md:flex py-3 px-8 bg-brand-blue h-16 justify-between z-10"
    >
      <div class="flex wrap">
        <base-button
          v-if="!subscriptionMenu"
          data-testid="home-btn"
          color="white"
          to="/boards"
          icon="Home"
          responsive
          icon-size="20"
          class="py-1"
          >{{ $t('nav.home') }}</base-button
        >
        <base-button
          v-else
          data-testid="home-btn"
          color="white"
          to="/boards"
          icon="chevron-left"
          responsive
          icon-size="16"
          class="py-1"
          >{{ $t('navbar.back') }}</base-button
        >
        <header-create-menu
          v-if="visibleCreateMenu && !subscriptionMenu"
          data-testid="create-btn"
          class="mr-2"
        />
      </div>
      <div class="max-w-6xl flex items-center">
        <header-search data-testid="header-search-btn" class="mr-4" />
        <!-- notification -->
        <list-notification data-testid="notification-btn" class="mr-4" />
        <!-- upgrade -->
        <base-button
          v-if="
            !subscriptionMenu &&
              !profile.haveActiveSubscription &&
              !profile.subscriptionExempted
          "
          class="header-upgrade-btn"
          color="white"
          icon="UpgradeWorkspace"
          responsive
          icon-size="21"
          icon-view-box="0 0 22 22"
          @click="$modal.show('plan-card')"
          >{{ $t('upgrade') }}</base-button
        >
        <!-- profile menu -->
        <header-navigation-profile data-testid="header-profile-btn" />
      </div>
    </header>
    <!-- mobile -->
    <header
      class="flex md:hidden py-3 px-6 bg-brand-blue h-16 justify-between z-10"
    >
      <base-button
        color="white"
        icon="Hamberger"
        responsive
        icon-size="18"
        class="py-1"
        @click="drawerOpen = true"
      />
      <header-navigation-profile />
    </header>

    <base-drawer :open="drawerOpen" @change="drawerOpen = $event">
      <div class="py-4">
        <subscription-sidebar v-if="isSubscription" force-show />
        <sidebar-navigation v-else force-show />
      </div>
    </base-drawer>

    <modal-box
      width="569"
      data-testid="modal-feedback"
      name="feedback"
      height="auto"
      header-borderless
    >
      <h3 slot="header">{{ $t('feedback.send_feedback') }}</h3>
      <form-feedback />
    </modal-box>

    <div v-if="isShowSearch" class="h-16" />
  </div>
</template>

<script>
import HeaderSearch from '@/components/header-search';
import ListNotification from '@/components/list-notification';
import FormFeedback from './forms/form-feedback.vue';
import ModalBox from '@/components/modal/modal-box';
import HeaderCreateMenu from '@/components/header/header-create-menu';
import HeaderNavigationProfile from '@/components/header-navigation-profile';
import SidebarNavigation from '@/components/sidebar-navigation';
import { mapGetters } from 'vuex';
import moment from 'moment';
import subscriptionSidebar from '@/components/subscription/subscription-sidebar';

export default {
  components: {
    HeaderSearch,
    ListNotification,
    FormFeedback,
    ModalBox,
    HeaderCreateMenu,
    HeaderNavigationProfile,
    SidebarNavigation,
    subscriptionSidebar
  },
  data() {
    return {
      showDropDown: false,
      drawerOpen: false,
      isAlertSubscription: false,
      dayAlertSubscription: 15,
      daysRemaining: 0
    };
  },
  methods: {
    closeAlertSubscription() {
      const now = new Date().getTime();
      localStorage.setItem('setupTime_header_expired_subscription', now);
      this.isAlertSubscription = false;
    },
    checkNotiSubscription() {
      const millisecond = 60 * 60 * 1000;
      const hoursForModal = 3;
      const hoursForHeader = 1;
      const now = new Date().getTime();
      const getTime_modal = localStorage.getItem(
        'setupTime_modal_expired_subscription'
      );
      const getTime_header = localStorage.getItem(
        'setupTime_header_expired_subscription'
      );
      if (getTime_modal == null || getTime_header == null) {
        localStorage.setItem('setupTime_modal_expired_subscription', now);
        localStorage.setItem('setupTime_header_expired_subscription', now);
      } else {
        if (now - getTime_modal > hoursForModal * millisecond) {
          this.$modal.show('subscription-expired');
        } else if (now - getTime_header > hoursForHeader * millisecond) {
          this.isAlertSubscription = true;
        }
      }
    },
    renewPlan() {
      this.$modal.show('subscription-expired');
    },
    checkRequireSubscription() {
      if (this.isRequireSubscription && !this.isCanFreeTrial) {
        const timeRequireSubscription = 2 * 60 * 60 * 1000;
        const now = new Date().getTime();

        const getTime_requireSubscription = localStorage.getItem(
          'setupTime_require_subscription'
        );

        if (getTime_requireSubscription == null) {
          localStorage.setItem('setupTime_require_subscription', now);
        } else {
          if (now - getTime_requireSubscription > timeRequireSubscription) {
            this.$modal.show('subscription-expired');
          }
        }
      }
    },
    foreSubscription() {
      if (this.isForceSubscription) {
        this.$modal.show('plan-card');
      }
    }
  },
  watch: {
    $route(router) {
      this.foreSubscription();
      this.drawerOpen = false;
    }
  },
  async mounted() {
    await this.$store.dispatch('subscription/fetchCurrentPlan');
    this.checkRequireSubscription();
    const setTimeOfDayToZero = {
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    };
    await this.foreSubscription();
    if (this.getCurrentPlan.payment) {
      const eventdate = moment(this.currentPlanEndAt);
      const todaysdate = moment().set(setTimeOfDayToZero);
      this.daysRemaining = eventdate.diff(
        todaysdate.set(setTimeOfDayToZero),
        'days'
      );
      if (this.daysRemaining <= this.dayAlertSubscription) {
        this.checkNotiSubscription();
      }
    }
  },
  computed: {
    ...mapGetters('subscription', ['getCurrentPlan']),
    visibleCreateMenu() {
      const isInsideBoardDetail = route => route.name === 'Boards-Id';

      return !this.$route.matched.some(isInsideBoardDetail);
    },
    subscriptionMenu() {
      const subscriptionRoute = [
        'Subscription-Overview',
        'Subscription-Payment',
        'Subscription-SeatManagement',
        'Subscription-Space'
      ];
      return subscriptionRoute.find(route => route == this.$route.name);
    },
    isShowSearch() {
      return this.$store.getters['notifications/getIsShowSearch'];
    },
    currentPlanEndAt() {
      return this.getCurrentPlan.payment?.endAt;
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    isRequireSubscription() {
      return this.profile.requireSubscription;
    },
    isSubscription() {
      return this.$route.matched[2].name == 'Subscription';
    },
    isForceSubscription() {
      return this.profile.forceSubscription;
    },
    isCanFreeTrial() {
      return this.profile.canFreeTrial;
    }
  }
};
</script>

<style scoped>
.header-upgrade-btn {
  background-color: #ffd000;
  border-color: #ffd000;
}

.subscription-alert-btn {
  @apply underline cursor-pointer mx-2;
  color: #2f80ed;
}

.subscription-dot {
  background: #000000;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
</style>

<i18n>
{
  "en": {
    "upgrade": "Upgrade",
    "navbar":{
      "title_alert_subscription": "Your monthly payment plan is going to expire in the next {count} days.",
      "renew_subscription": "Proceed to Payment",
      "cancel_subscription": "Later",
      "back": "Home"
    }
  },
  "th": {
    "upgrade": "อัปเกรด",
    "navbar":{
      "title_alert_subscription": "แผนชำระเงินรายเดือนของคุณกำลังจะหมดอายุในอีก {count} วัน",
      "renew_subscription": "ดำเนินการ ชำระเงิน ",
      "cancel_subscription": "ไว้ทีหลัง",
      "back": "กลับสู่หน้าหลัก"
    }
  }
}
</i18n>
