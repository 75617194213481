import { ApiCommonFields } from './Api';
import { Board } from './Board';
import { Task } from './Task';
import { Team } from './Team';

export enum NotificationType {
  Board = 0,
  Task = 1,
  Team = 4,
  TeamDelete = 5,
  SubscriptionSeat = 6
}

export interface NotificationSetting extends ApiCommonFields {
  isBoard: boolean;
  isTask: boolean;
}

export interface NotificationActivity {
  createdAt: string;
  deletedAt: string;
  id: number;
  messageEN: string;
  messageTH: string;
  notificationID: number;
  senderID: number;
  updatedAt: string;
  userDisplayName: string;
  userAvatarPath: string;
}

export interface NotificationItem {
  boardID: number;
  createdAt: string;
  deletedAt: string;
  id: number;
  receiverID: number;
  taskID?: number;
  teamID?: number;
  type: NotificationType;
  unread: boolean;
  updatedAt: string;
  activities: NotificationActivity[];
  board: Board;
  task?: Task;
  team?: Team;
}
