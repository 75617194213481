<template>
  <div>
    <div class="flex justify-center">
      <div
        v-if="isMobile"
        :style="{ 'max-height': `${height}px` }"
        class="overflow-hidden flex items-center py-8"
      >
        <img :src="image" alt="" />
      </div>
      <div id="croppie" class="" />
      <input
        ref="upload"
        type="file"
        value="Choose a file"
        style="display:none"
        accept="image/*"
        v-on:change="setUpFileUploader"
      />
    </div>
  </div>
</template>
<script>
import Croppie from 'croppie';
export default {
  props: {
    defaultImage: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 320
    },
    circle: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 320
    }
  },
  data() {
    return {
      croppie: null,
      image: null
    };
  },
  mounted() {
    this.$on('imgUploaded', function(imageData) {
      this.croppie.destroy();
      this.image = imageData;
      this.setUpCroppie();
    });
    this.image = this.defaultImage;
    this.setUpCroppie();
  },
  methods: {
    emitUpload() {
      this.$refs.upload.click();
    },
    cropeImage() {
      if (this.croppie === null) {
        this.$emit('update:defaultImage', this.image);
      } else {
        this.croppie
          .result({
            type: 'canvas',
            size: 'viewport',
            quality: 1
          })
          .then(response => {
            this.image = response;
            this.$emit('update:defaultImage', response);
          });
      }
    },
    setUpCroppie() {
      const el = document.getElementById('croppie');
      this.croppie = new Croppie(el, {
        viewport: {
          width: this.width,
          height: this.height,
          type: this.circle ? 'circle' : 'square'
        },
        boundary: { width: this.width + 40, height: this.height + 40 },
        showZoomer: true,
        enableOrientation: true,
        circle: this.circle ? 'true' : 'false',
        enableExif: true
      });
      this.croppie.bind({
        url: this.image
      });
    },
    setUpFileUploader(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.image = e.target.result;
        this.$emit('imgUploaded', e.target.result);
      };
      reader.readAsDataURL(file);
    }
  },
  computed: {
    isMobile() {
      const openOnMoile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      return openOnMoile;
    }
  },
  components: {}
};
</script>

<style>
.cr-viewport {
  outline: none;
}
.cr-slider-wrap {
  display: none;
}
</style>
