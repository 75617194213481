<template>
  <div class="feedback-form">
    <!-- <div class="feedback-form__description">
      <span>{{ $t('feedback.suggestion_title1') }}? </span>
      <span>{{ $t('feedback.suggestion_title2') }}</span>
    </div> -->
    <div>
      <div class="mb-5">
        <label>
          <p class="label-title">{{ $t('feedback.subject') }}:</p>
          <base-input-text
            v-model="subject"
            :placeholder="$t('feedback.subject')"
          />
        </label>
      </div>
      <div class="mb-5">
        <label>
          <p class="label-title">{{ $t('feedback.improved') }}?</p>
          <textarea
            v-model="message"
            class="base-input base-input--textarea overflow-auto"
            rows="4"
            :placeholder="$t('feedback.write')"
          />
        </label>
      </div>
      <div class="pt-5 text-right">
        <base-button
          :disabled="disabled"
          :loading="loading"
          wide="px-8"
          @click="sendFeedback"
        >
          {{ $t('feedback.button_send') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import apis from '@/services/apis';
export default {
  data() {
    return {
      loading: false,
      subject: '',
      message: '',
      submitSuccess: false
    };
  },
  computed: {
    disabled() {
      return !this.subject || !this.message;
    }
  },
  methods: {
    async sendFeedback() {
      this.loading = true;
      try {
        await apis.post('/feedback', {
          title: this.subject,
          message: this.message
        });
        this.$notify({
          type: 'success',
          title: this.$t('feedback.received'),
          text: this.$t('feedback.thank'),
          duration: 5000,
          group: 'app-noti'
        });
        this.$modal.hide('feedback');
        this.submitSuccess = true;
      } catch (error) {
        //
      }

      this.subject = '';
      this.message = '';
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.feedback-form {
  @apply -mt-5;
  &__description {
    @apply text-gray-600 font-light mb-10;
  }
  &__label {
    @apply text-lg;
  }
}
</style>
