

















import Vue from 'vue';
import { mapActions } from 'vuex';
import { store } from '@/store';
import HeaderNavigation from '@/components/header-navigation.vue';
import localJwt from '@/services/local-jwt';

export default Vue.extend({
  components: { HeaderNavigation },
  name: 'Boards',
  computed: {
    isColumnPage(): boolean {
      const routeName = 'Board-Columns';
      return this.$route.matched.some(r => r.name === routeName);
    },
    isUser(): boolean {
      return Boolean(this.$store.getters['auth/isUser']);
    }
  },

  created() {
    if (this.isUser) {
      this.subscribeWorking();
    }
  },
  async beforeRouteEnter(_0, _1, next) {
    try {
      if (localJwt.hasAccessToken()) await store.dispatch('auth/fetchMe');
      next();
    } catch (error) {
      next({ name: 'Landing' });
    }
  },
  beforeRouteLeave(_0, _1, next) {
    this.unsubscribeWorking();
    next();
  },
  methods: {
    ...mapActions('time-log', ['subscribeWorking', 'unsubscribeWorking'])
  }
});
