<template>
  <div class="w-0 h-0">
    <notifications
      group="change-profile"
      class="mt-2"
      :duration="3000"
      classes="vue-notification mr-2 text-base shadow"
    />
    <notifications
      group="change-password"
      class="mt-2"
      :duration="5000"
      classes="vue-notification mr-2 text-base shadow"
    />
    <modal
      name="modal-profile"
      class="modal-profile"
      adaptive
      scrollable
      height="493"
      width="800"
    >
      <div class="flex flex-col" data-testid="modal-profile-setting">
        <notifications
          group="email-verification"
          class="mt-2"
          :duration="5000"
          classes="vue-notification mr-2 text-base shadow"
        />
        <div class="flex">
          <div class="flex-grow text-2xl px-8 py-3">
            {{ $t('account.title') }}
          </div>
          <div class="flex-grow-0 px-8 py-4">
            <div @click="close">
              <base-icon class="pointer" icon-size="18" name="close">
                <icon-close />
              </base-icon>
            </div>
          </div>
        </div>
        <ul class="profile-tab">
          <li
            :class="{ active: tabIndex == 0 }"
            data-testid="profile-tab"
            @click="tabIndex = 0"
          >
            {{ $t('account.profile.name') }}
          </li>
          <li
            :class="{ active: tabIndex == 1 }"
            data-testid="password-tab"
            @click="tabIndex = 1"
          >
            {{ $t('account.password.name') }}
          </li>
          <li
            :class="{ active: tabIndex == 2 }"
            data-testid="notification-tab"
            @click="tabIndex = 2"
          >
            {{ $t('account.notifications.name') }}
          </li>
          <li
            :class="{ active: tabIndex == 3 }"
            data-testid="trash-tab"
            @click="tabIndex = 3"
          >
            {{ $t('account.trash.name') }}
          </li>
          <li
            :class="{ active: tabIndex == 4 }"
            data-testid="delete-account-tab"
            @click="tabIndex = 4"
          >
            {{ $t('account.delete.name') }}
          </li>
        </ul>
        <v-scroller height="380px" @bottom="scrollBottom">
          <div class="px-8 py-3 flex-1">
            <div v-show="tabIndex == 0">
              <div class="profile-header">
                <div class="header" />
                <base-dropdown
                  class="space-avatar"
                  width="medium"
                  position="bottom-left"
                >
                  <div slot="toggle" class="avatar">
                    <img
                      data-testid="profile-image-btn"
                      class="w-24 h-24 object-cover rounded-full flex-shrink-0"
                      :src="avatar || profileImg"
                    />
                    <div>
                      <div class="change">
                        {{ $t('account.profile.avatar.change') }}
                      </div>
                    </div>
                  </div>
                  <base-dropdown-item :hoverable="false" class="p-0">
                    <div>{{ $t('account.profile.avatar.upload') }}</div>
                    <div
                      class="space-click text-sm mt-2"
                      data-testid="upload-image-btn"
                      @click="uploadAvatar"
                    >
                      {{ $t('account.profile.avatar.click') }}
                    </div>
                  </base-dropdown-item>
                </base-dropdown>
              </div>
              <div class="mt-4">
                <div class="mb-4">
                  <label>
                    <p class="mb-2">{{ $t('account.profile.myname') }}</p>
                    <base-input-text
                      v-model="profileData.name"
                      data-testid="name-input"
                      :disabled="loading"
                      placeholder="Your name"
                      type="text"
                    />
                  </label>
                </div>
                <div class="mb-4">
                  <label>
                    <p class="mb-2">{{ $t('account.profile.email.name') }}</p>
                    <div class="relative">
                      <base-input-text
                        v-model="email"
                        data-testid="email-input"
                        :disabled="loading"
                        placeholder="Your email"
                        :icon="
                          profile.verifyEmailType === 2
                            ? 'IconVerify'
                            : 'IconNotVerify'
                        "
                        icon-position="right"
                        type="email"
                        inner-class="pr-4-i"
                      />
                    </div>
                    <div v-if="profile.verifyEmailType === 1">
                      <div class="text-sm" style="color: #7A869A;">
                        {{ $t('account.profile.email.verification') }}?
                      </div>
                      <div
                        class="text-sm"
                        data-testid="request-another-link"
                        style="color: #168AE2; cursor: pointer;"
                        @click="sendVerificationMail"
                      >
                        {{ $t('account.profile.email.request') }}
                      </div>
                    </div>
                    <div v-else-if="profile.verifyEmailType === 3">
                      <div class="text-sm" style="color: #7A869A;">
                        {{ $t('account.profile.email.wait') }}
                      </div>
                      <div class="text-sm" style="color: #7A869A;">
                        {{ $t('account.profile.email.history') }}
                        "{{ oldEmail }}"
                        {{ $t('account.profile.email.communication') }}
                      </div>
                      <div
                        class="text-sm"
                        style="color: #168AE2; cursor: pointer;"
                        @click="sendVerificationMail"
                      >
                        {{ $t('account.profile.email.request') }}
                      </div>
                    </div>
                  </label>
                </div>
                <div class="mb-4">
                  <label>
                    <p class="mb-2">
                      {{ $t('account.profile.bio') }}
                      <span class="text-gray-600"
                        >({{ $t('account.profile.option') }})</span
                      >
                    </p>
                    <textarea
                      v-model="profileData.bio"
                      data-testid="description-textarea"
                      class="base-input base-input--textarea"
                      rows="4"
                      :placeholder="$t('account.profile.about_me')"
                      type="textarea"
                      :disabled="loading"
                    />
                  </label>
                </div>
              </div>
              <div class="flex justify-end pb-4">
                <base-button
                  color="white"
                  data-testid="cancel-btn"
                  class="border border-gray-400"
                  wide="px-10"
                  @click="close"
                  >{{ $t('account.cancel') }}</base-button
                >
                <base-button
                  color="brand"
                  wide="px-10"
                  data-testid="save-btn"
                  :disabled="loading"
                  :loading="loading"
                  @click="saveProfileClick"
                  >{{ $t('account.save') }}</base-button
                >
              </div>
            </div>
            <div v-show="tabIndex == 1">
              <div class="text-xl mt-4">
                {{ $t('account.password.change') }}
              </div>
              <form
                :disabled="loading"
                class
                @submit.prevent="changePassword()"
              >
                <div class="mt-8">
                  <div class="mb-6">
                    <label>
                      <p class="mb-2">{{ $t('account.password.current') }}</p>
                      <base-input-password
                        v-model="currentPassword"
                        data-testid="current-password-input"
                        :disabled="loading"
                        type="password"
                      />
                    </label>
                  </div>
                  <div class="mb-6">
                    <label>
                      <p class="mb-2">{{ $t('account.password.new') }}</p>
                      <base-input-password
                        v-model="newPassword"
                        data-testid="new-password-input"
                        :disabled="loading"
                        type="password"
                      />
                    </label>
                  </div>
                  <div class="mb-6">
                    <label>
                      <p class="mb-2">{{ $t('account.password.confirm') }}</p>
                      <base-input-password
                        v-model="confirmPassword"
                        :disabled="loading"
                        data-testid="confirm-password-input"
                        type="password"
                        name="password"
                        :class="{ error: invalidConfirmPassword }"
                      />
                    </label>
                    <div v-if="error" class="mt-1 mb-6 color-error">
                      {{ error }}
                    </div>
                  </div>
                </div>
                <div class="flex justify-end pb-4">
                  <base-button
                    color="transparent"
                    wide="px-10"
                    data-testid="cancel-btn"
                    @click="close"
                  >
                    {{ $t('account.cancel') }}
                  </base-button>
                  <base-button
                    wide="px-10"
                    :color="isCanSubmit ? 'brand' : 'disabled'"
                    :disabled="!isCanSubmit"
                    :loading="loading"
                    data-testid="save-btn"
                    type="submit"
                    >{{ $t('account.save') }}</base-button
                  >
                </div>
              </form>
            </div>
            <div v-show="tabIndex == 2">
              <div class="text-xl mt-4 mb-6">
                {{ $t('account.notifications.name') }}
              </div>
              <div class="flex mb-6" data-testid="task-update-btn">
                <div class="flex-grow-0 pr-4">
                  <label class="switch">
                    <input
                      v-model="notificationData.isTask"
                      type="checkbox"
                      @change="updateNotificationEvent"
                    />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="flex-grow">
                  <div class="text-lg mb-1">
                    {{ $t('account.notifications.task.name') }}
                  </div>
                  <p class="text-gray-600 text-sm">
                    {{ $t('account.notifications.task.message') }}
                  </p>
                </div>
              </div>
              <div class="flex mb-6" data-testid="board-update-btn">
                <div class="flex-grow-0 pr-4">
                  <label class="switch">
                    <input
                      v-model="notificationData.isBoard"
                      type="checkbox"
                      @change="updateNotificationEvent"
                    />
                    <span class="slider round" />
                  </label>
                </div>
                <div class="flex-grow">
                  <div class="text-lg mb-1">
                    {{ $t('account.notifications.boards.name') }}
                  </div>
                  <p class="text-gray-600 text-sm">
                    {{ $t('account.notifications.boards.message') }}
                  </p>
                </div>
              </div>
            </div>
            <div v-show="tabIndex == 3">
              <div class="text-xl mt-4 mb-2">
                {{ $t('account.trash.name') }}
                <span class="text-sm text-gray-caption ml-3"
                  >{{ totalTrash }} {{ $t('account.trash.board') }}</span
                >
              </div>
              <div class="profile-trash mb-3">
                <div v-if="trashs.length">
                  <p class="text-sm text-gray-caption mb-2">
                    {{ $t('account.trash.message') }}
                  </p>
                  <div
                    v-for="(t, i) in trashs"
                    :key="`project-${i}`"
                    class="flex flex-no-wrap"
                  >
                    <div class="flex-none py-3">
                      <div
                        class="profile-folder"
                        :style="
                          `background: ${
                            t.backgroundCover ? t.backgroundCover : '6FCF97'
                          }88;`
                        "
                      >
                        <base-icon
                          class="svg"
                          :width="24"
                          view-box="0 0 10 10"
                          name="folder"
                        >
                          <icon-folder
                            :fill="
                              `${
                                t.backgroundCover ? t.backgroundCover : '6FCF97'
                              }`
                            "
                          />
                        </base-icon>
                      </div>
                    </div>
                    <div
                      class="flex-grow text-base px-3 py-3 max-w-full truncate"
                      data-testid="name-board-trash"
                    >
                      <p>
                        {{ t.name }}
                      </p>
                      <p class="text-sm text-gray-600">
                        {{ $t('account.trash.deleteAt') }}
                        {{ formatDate(t.trashedAt) }}
                      </p>
                    </div>
                    <div class="flex-none p-3">
                      <base-dropdown
                        position="top-right"
                        width="small"
                        data-testid="option-btn"
                      >
                        <base-icon slot="toggle" color="gray" class="pointer">
                          <icon-dots-horizontal />
                        </base-icon>
                        <base-dropdown-item @click="trashRestoreClick(t)">{{
                          $t('account.trash.restore')
                        }}</base-dropdown-item>
                      </base-dropdown>
                    </div>
                  </div>
                </div>
                <div v-else class="px-4 py-20 text-center text-gray-500">
                  {{ $t('account.trash.message') }}
                </div>
              </div>
            </div>
            <div v-show="tabIndex == 4">
              <div class="text-xl mt-4 mb-3">
                {{ $t('account.delete.name') }}
              </div>
              <div class="flex-grow">
                <div class="text-base mb-1">
                  {{ $t('account.delete.account') }}
                  {{ oldEmail }} ?
                </div>
                <div>
                  <span class="text-red-600 text-sm">
                    {{ $t('account.delete.warning') }}: &nbsp;
                  </span>
                  <span class="text-sm">
                    <span class="text-gray-600">
                      {{ $t('account.delete.have') }}
                    </span>
                    <span class="mb-1">
                      {{ boards.length }}
                      {{ $t('account.delete.board') }}
                    </span>
                    <span class="text-gray-600">
                      {{ $t('account.delete.recover') }}
                    </span>
                  </span>
                </div>
              </div>

              <div class="flex-grow mt-8 mb-3">
                <div class="text-base mb-1">
                  {{ $t('account.delete.deleting') }}
                </div>
                <span class="text-gray-600 text-sm">
                  {{ $t('account.delete.workkami') }}
                </span>
              </div>
              <div class="flex mt-4 border-b-1 pb-1" />
              <div class="flex-grow mt-4 mb-3">
                <div class="text-base mb-1">
                  {{ $t('account.delete.what') }}
                </div>
                <span class="text-gray-600 text-sm">
                  {{ $t('account.delete.description') }}
                </span>
              </div>
              <div class="pt-1 mb-8">
                <base-button
                  data-testid="delete-account-btn"
                  wide="px-8 py-2"
                  type="button"
                  color="gray2"
                  class="hover:bg-red-600 hover:text-white "
                  @click="deleteAccout"
                >
                  {{ $t('account.delete.button') }}
                </base-button>
              </div>
              <modal-delete-account />
            </div>
          </div>
        </v-scroller>
        <modal
          name="avatar-crop-modal"
          :adaptive="true"
          :max-width="420"
          height="auto"
        >
          <div class="px-5 py-4" :class="{ hide: !avatarUploaded }">
            <div class="text-xl">{{ $t('account.profile.avatar.upload') }}</div>
            <div class="mt-2 mb-2">
              <avatar-crop
                ref="avatarCrop"
                :circle="false"
                @imgUploaded="avatarUploaded = true"
                @update:defaultImage="avatarCroped"
              />
            </div>
            <div class="flex justify-end mt-4">
              <base-button
                wide="px-8 py-2"
                color="white"
                type="button"
                @click="closeSelectAvatar"
                >{{ $t('account.cancel') }}</base-button
              >
              <base-button
                wide="px-8 py-2"
                color="brand"
                type="button"
                @click="cropSelectAvatar"
                >{{ $t('account.save') }}</base-button
              >
            </div>
          </div>
        </modal>
      </div>
    </modal>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose';
import IconFolder from '@/components/icons/IconFolder';
import IconDotsHorizontal from '@/components/icons/IconDotsHorizontal';
import { boardsApi, profilesApi, filesApi } from '@/services/apis';
import LoadingMixin from '@/mixins/LoadingMixin';
import EventBus from '@/services/eventBus';
import VScroller from '@/components/scroller';
import ModalDeleteAccount from '@/components/modal/model-delete-account';
import AvatarCrop from '@/components/avatar-crop';
import moment from 'moment';

export default {
  components: {
    IconClose,
    IconFolder,
    IconDotsHorizontal,
    AvatarCrop,
    VScroller,
    ModalDeleteAccount
  },
  mixins: [LoadingMixin],
  watch: {
    confirmPassword: function(confirmPassword) {
      if (confirmPassword.length) {
        this.isCanSubmit = true;
      } else {
        this.isCanSubmit = false;
      }
    }
  },
  data() {
    return {
      tabIndex: 0,
      name: '',
      bio: '',
      profileData: {},
      notificationData: {},
      trashs: [],
      totalTrash: '',
      trashsPage: {
        page: 1,
        lastPage: 1
      },
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isCanSubmit: false,
      invalidConfirmPassword: false,
      error: '',
      email: '',
      oldEmail: '',
      avatar: undefined,
      avatarUploaded: false,
      boards: ''
    };
  },
  methods: {
    async show(tabIndex) {
      this.tabIndex = tabIndex;
      this.$modal.show('modal-profile');
      this.profileData = {
        name: this.profile.name,
        bio: this.profile.bio
      };
      this.oldEmail = this.profile.email;
      this.email = this.profile.changeToEmail
        ? this.profile.changeToEmail
        : this.profile.email;
      this.notificationData = {
        isBoard: this.profile.notificationSetting.isBoard,
        isTask: this.profile.notificationSetting.isTask
      };

      // clear
      this.currentPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.invalidConfirmPassword = false;
      this.error = '';

      const meBoards = await boardsApi.index();
      this.boards = meBoards.data.all;
      const res = await boardsApi.trash();
      this.trashs = res.data.entities;
      this.trashsPage = {
        page: res.data.pageInformation.page,
        lastPage: res.data.pageInformation.lastPage
      };
      this.totalTrash = res.data.pageInformation.count;
      this.avatar = undefined;
    },
    close() {
      this.$modal.hide('modal-profile');

      this.avatar = undefined;
    },
    async saveProfileClick() {
      const group = 'change-profile';
      let profileUrl = undefined;

      this.onLoading();

      if (this.avatar) {
        // upload avatar

        const arr = this.avatar.split(',');
        const bstr = atob(arr[1]);
        const mime = arr[0].match(/:(.*?);/)[1];
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const file = new File([u8arr], 'images.png', { type: mime });
        const data = await filesApi.upload('users', [file]);
        if (data.data && data.data.length) {
          profileUrl = data.data[0].thumbnailImageUrl;
        }
      } else {
        if (this.profile.profilePicture)
          profileUrl = this.profile.profilePicture;
      }
      if (
        this.email !== this.profile.email ||
        (this.profile.changeToEmail &&
          this.email !== this.profile.changeToEmail)
      ) {
        this.profileData.newEmail = this.email;
      }
      this.profileData.profilePicture = profileUrl;

      try {
        await profilesApi.updateProfile(this.profileData);
        await this.$store.dispatch('auth/fetchMe');

        this.$notify({
          text: this.$t('notifications.popup.profileImage'),
          type: 'success',
          group
        });
        this.close();
      } catch (error) {
        this.$notify({
          text: error.response.data.message,
          type: 'error',
          group
        });
      }

      this.offLoading();
    },
    async updateNotificationEvent() {
      this.onLoading();

      await profilesApi.updateNotification(this.notificationData);

      this.$store.commit('auth/SET_USER', {
        ...this.profile,
        notificationSetting: this.notificationData
      });

      this.offLoading();
    },
    async trashRestoreClick(t) {
      this.onLoading();

      await boardsApi.restoreArchivedBoard(t.id);

      const res = await boardsApi.trash();
      this.trashs = res.data.entities;

      EventBus.$emit('UPDATE_BOARDS', {});
      this.$store.dispatch('boards/fetchArchives');

      if (this.$route.name == 'Teams-Board') {
        await this.$store.dispatch('teams/fetchBoards', this.$route.params.id);
      }

      this.totalTrash = this.trashs.length;
      this.offLoading();
    },
    async scrollBottom() {
      if (this.trashsPage.page + 1 > this.trashsPage.lastPage) return;

      const res = await boardsApi.trash({
        page: this.trashsPage.page + 1
      });
      this.trashs = this.trashs.concat(res.data.entities);
      this.trashsPage = {
        page: res.data.pageInformation.page,
        lastPage: res.data.pageInformation.lastPage
      };
    },
    async sendVerificationMail() {
      this.onLoading();
      await this.$store.dispatch('auth/resendVerifyEmail');
      const group = 'email-verification';
      this.$notify({
        text: this.$t('notifications.popup.email'),
        type: 'success',
        group
      });
      this.offLoading();
    },
    async changePassword() {
      this.error = '';
      this.invalidConfirmPassword = false;
      if (!this.isCanSubmit) return;
      if (this.newPassword != this.confirmPassword) {
        this.error = this.$t('login.signup.error.notmatch');
        this.invalidConfirmPassword = true;
      }
      this.onLoading();
      const { newPassword, currentPassword } = this.$data;
      const group = 'change-password';
      if (this.newPassword == this.confirmPassword) {
        try {
          await this.$store.dispatch('auth/changePassword', {
            currentPassword,
            newPassword
          });
          this.$notify({
            text: this.$t('notifications.popup.password'),
            type: 'success',
            group
          });
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.close();
        } catch (error) {
          this.error = error?.response?.data?.message;
        }
      }
      this.offLoading();
    },
    uploadAvatar() {
      const self = this;

      this.avatarUploaded = false;
      this.$modal.show('avatar-crop-modal');

      setTimeout(() => {
        self.$refs.avatarCrop.emitUpload();
      }, 100);
    },
    avatarCroped(data) {
      this.avatar = data;
    },
    closeSelectAvatar() {
      this.$modal.hide('avatar-crop-modal');
    },
    cropSelectAvatar() {
      this.$refs.avatarCrop.cropeImage();
      this.$modal.hide('avatar-crop-modal');
    },
    deleteAccout() {
      this.$modal.show('delete-account');
    },
    formatDate(date) {
      const mCreatedAt = moment(date);
      const diffFromNowInADay = moment().diff(mCreatedAt, 'days', true);
      if (diffFromNowInADay < 1) return mCreatedAt.fromNow();
      const formatter = function(e) {
        return mCreatedAt.format(e);
      };
      const dateStringFormat = diffFromNowInADay < 7 ? 'ddd DD' : 'DD MMM';
      const dateString = formatter(dateStringFormat);
      const timeString = formatter('HH:mm');
      const fullDateTime = `${dateString} ${timeString}`;
      return fullDateTime;
    }
  },
  computed: {
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    profileImg() {
      return (
        this.profile.profilePicture ||
        'https://www.pngarts.com/files/5/User-Avatar-PNG-Image.png'
      );
    }
  },
  directives: {}
};
</script>
