var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dropdown',{staticClass:"self-stretch inline-flex nav-notification",attrs:{"position":"bottom-right","max-height":"560","width":"large"},on:{"opened":_vm.fetchNotifications,"bottom":_vm.loadMoreNoti}},[_c('div',{staticClass:"flex h-full",attrs:{"slot":"toggle"},slot:"toggle"},[_c('base-button',{attrs:{"data-testid":"notification-btn","color":"white","wide":"px-3 py-0","icon":"bell","icon-size":"32"}}),(_vm.getUnreads)?_c('span',{staticClass:"unreads--badge"},[_vm._v(_vm._s(_vm.unreadDisplay))]):_vm._e()],1),_c('div',{style:({ height: (_vm.headerHeight + "px") }),attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.$t('notifications.name')))]),_c('base-button',{staticClass:"text-brand-blue text-xs font-medium ml-2",attrs:{"data-testid":"read-all-btn","color":"text","wide":"p-2"},on:{"click":_vm.readAll}},[_vm._v(_vm._s(_vm.$t('notifications.read')))])],1),(_vm.isEmpty)?_c('base-dropdown-item',{attrs:{"hoverable":false}},[_c('div',{staticClass:"py-4"},[_c('img',{staticClass:"mx-auto",staticStyle:{"max-width":"170px"},attrs:{"src":require("@/assets/image/notification-empty.png")}}),_c('p',{staticClass:"text-center font-medium mt-5"},[_vm._v(" "+_vm._s(_vm.$t('notifications.no_notification'))+" ")])])]):_vm._e(),_vm._l((_vm.groupedByDayNotifications),function(groupedNotification,key){return _c('base-dropdown-item',{key:key,attrs:{"hoverable":false}},[_c('p',{staticClass:"sticky bg-white py-2 z-10",style:({ top: _vm.stickyTop })},[_vm._v(" "+_vm._s(_vm.getCalendarDate(key))+" ")]),_vm._l((groupedNotification),function(noti){return _c('div',{key:noti.id,staticClass:"item--normal",class:{
        'item--unread': _vm.isUnread(noti),
        'italic text-gray-600 text-sm': !_vm.getItemHeader(noti)
      },on:{"click":function($event){return _vm.openLink(noti)}}},[_c('p',{staticClass:"text-sm text-gray-caption"},[_vm._v(_vm._s(_vm.getTaskCode(noti)))]),(_vm.getItemType(noti) != '')?_c('v-clamp',{staticClass:"text-lg font-medium mb-1",attrs:{"autoresize":"","max-lines":3}},[_c('span',{staticClass:"text-xs bg-gray-600 text-white px-2 rounded-full mr-2 shadow-sm",class:{
            'bg-brand-blue': _vm.getItemType(noti) === _vm.$t('notifications.task'),
            'bg-green-success':
              _vm.getItemType(noti) === _vm.$t('notifications.board'),
            ' bg-orange-500': _vm.getItemType(noti) === _vm.$t('notifications.team')
          },attrs:{"slot":"before"},slot:"before"},[_vm._v(" "+_vm._s(_vm.getItemType(noti))+" ")]),_vm._v(" "+_vm._s(_vm.getItemHeader(noti) || 'unknown item')+" ")]):_vm._e(),(_vm.getItemType(noti) === _vm.$t('notifications.task'))?_c('v-clamp',{staticClass:"truncate text-gray-600",attrs:{"autoresize":"","max-lines":1}},[_c('span',{attrs:{"slot":"after"},slot:"after"},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.$t('notifications.board')))]),_vm._v(" : "+_vm._s(_vm.getTitleBoardTask(noti)))])]):_vm._e(),_c('base-list',{attrs:{"items":noti.activities,"list-class":"py-1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full flex"},[_c('div',{staticClass:"pr-2 py-1 flex-shrink-0"},[_c('base-avatar',{attrs:{"size-small":"","avatar":_vm.getUserAvatarPath(item)}})],1),_c('div',{staticClass:"w-full"},[_c('p',{staticClass:"font-medium text-2-line break-words"},[_vm._v(" "+_vm._s(_vm.getUserName(item))+" ")]),_c('p-view-more',{staticClass:"text-gray-700 text-sm"},[_c('span',{class:{
                      title: item.message.search('mention') != -1
                    },domProps:{"innerHTML":_vm._s(
                      _vm.markdownText(
                        _vm.$i18n.locale === 'en'
                          ? item.messageEN
                          : item.messageTH
                      )
                    )}})]),_c('p',{staticClass:"text-xs text-gray-600"},[_vm._v(_vm._s(_vm.formatDate(item)))])],1)])])]}}],null,true)})],1)})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }