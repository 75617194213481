

































































































































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';
import VClamp from 'vue-clamp';
import PViewMore from './paragraph-view-more.vue';
import {
  NotificationItem,
  NotificationType,
  NotificationActivity
} from '../types/Notification';
import { Board, BoardMember } from '../types/Board';
import { Avatar } from './base/base-avatar.vue';
import MarkdownTextMixin from '@/mixins/MarkdownText';
import DateTime from '@/mixins/DateTimeMixin';
import { ApiPaginationParams, PageInformation } from '@/types/Api';
import { mixins } from 'vue-class-component';
import { BoardRoutNames, TaskRouteName } from '@/router/boards-childrens';
import { boardsApi, tasksApi, teamsApi } from '@/services/apis';

type TaskRouteKey = keyof typeof TaskRouteName;
const boardRouteKeys: TaskRouteKey[] = ['Kanban', 'Waterfall', 'Backlog'];

const notificationModule = namespace('notifications');
const boardsModule = namespace('boards');

const REM_PIXEL = 16;
const PADDING_TOP_BOTTOM = 1;

@Component({
  mixins: [MarkdownTextMixin],
  components: {
    PViewMore,
    VClamp
  }
})
export default class ListNotification extends mixins(DateTime) {
  @notificationModule.Action('fetchNotifications')
  fetchNotifications!: (params?: ApiPaginationParams) => void;
  @notificationModule.Action('clearNotifications')
  clearNotifications!: () => void;
  @notificationModule.Action('readAll')
  readAll!: () => void;
  @notificationModule.Action('fetchNotificationsUnreads')
  fetchNotificationsUnreads!: () => void;
  @notificationModule.Action('setRead')
  readNoti!: (id: number) => Promise<void>;
  @notificationModule.Getter('getNotifications')
  getNotifications!: NotificationItem[];
  @notificationModule.Getter('getPageInformation')
  getPageInformations!: PageInformation;
  @notificationModule.Getter('getUnreads')
  getUnreads!: number;
  @boardsModule.Getter('getMembers') getMembers!: BoardMember[];
  scrolledToBottom = false;

  readonly headerHeight = 32;
  get stickyTop() {
    return `${PADDING_TOP_BOTTOM * REM_PIXEL + this.headerHeight}px`;
  }

  mounted() {
    this.fetchNotificationsUnreads();
  }

  get isEmpty(): boolean {
    return !this.getNotifications.length;
  }

  get groupedByDayNotifications() {
    return this.groupItemByCreatedAt(this.getNotifications);
  }

  loadMoreNoti() {
    const { page, lastPage } = this.getPageInformations;
    if (page < lastPage) {
      const params = {
        page: page + 1
      };
      this.fetchNotifications(params);
    }
  }

  getBoardRouteName = (board: Board) => {
    return BoardRoutNames[boardRouteKeys[board.type]];
  };

  getTaskRouteName = (board: Board) => {
    return TaskRouteName[boardRouteKeys[board.type]];
  };
  async isOpenerExisted<T>(
    req: { api: { get: (id: number) => Promise<T> }; id: number },
    cb: () => void
  ): Promise<void> {
    try {
      await req.api.get(req.id);
      cb();
    } catch (error) {
      /* eslint-disable no-console */
      console.error(error);
    }
  }
  isTaskExisted(id: number) {
    return tasksApi.get(id);
  }
  async openLink(noti: NotificationItem) {
    const { type, boardID, taskID, teamID, id, board } = noti;
    const gotoBoard = async () => {
      await this.isOpenerExisted({ api: boardsApi, id: boardID }, () => {
        const name = this.getBoardRouteName(board);
        this.$router.push({
          name,
          params: {
            id: boardID.toString()
          }
        });
      });
    };
    const gotoTask = async () => {
      if (!taskID) return;
      await this.isOpenerExisted({ id: taskID, api: tasksApi }, () => {
        const name = this.getTaskRouteName(board);
        this.$router.push({
          name,
          params: {
            id: boardID.toString(),
            task_id: taskID.toString()
          }
        });
      });
    };

    const gotoTeam = async () => {
      if (!teamID) return;
      await this.isOpenerExisted({ id: teamID, api: teamsApi }, () => {
        this.$router.push({
          name: 'Teams-Board',
          params: {
            id: String(teamID)
          }
        });
      });
    };

    await this.readNoti(id);
    switch (type) {
      case NotificationType.Board: {
        gotoBoard();
        break;
      }

      case NotificationType.Task: {
        gotoTask();
        break;
      }
      case NotificationType.Team: {
        gotoTeam();
        break;
      }
      default:
        break;
    }
  }

  get unreadDisplay(): string | number {
    const MAX_UNREADS = 99;
    return this.getUnreads < 99 ? this.getUnreads : `${MAX_UNREADS}+`;
  }

  @Watch('getUnreads')
  onUnreadsChanged(current: number, prev: number) {
    if (current > prev) {
      this.$fana.logEvent('notification_received');
    }
  }
  getTitleBoardTask(item: NotificationItem): string {
    if (item.type === NotificationType.Task && item.task) {
      return item.task ? item.board.name : '';
    }
    return '';
  }
  getTaskCode(item: NotificationItem): string {
    return item.task ? item.task.code : '';
  }

  getItemHeader(item: NotificationItem): string {
    if (item.type === NotificationType.Board) {
      return item.board ? item.board.name : '';
    }
    if (item.type === NotificationType.Task && item.task) {
      return item.task ? item.task.name : '';
    }
    if (item.type === NotificationType.Team && item.team) {
      return item.team ? item.team.name : '';
    }
    if (item.type === NotificationType.TeamDelete && item.team) {
      return item.team ? item.team.name : '';
    }
    return '';
  }

  getItemType(item: NotificationItem): string {
    if (item.type === NotificationType.Board) {
      return `${this.$t('notifications.board')}`;
    }
    if (item.type === NotificationType.Task) {
      return `${this.$t('notifications.task')}`;
    }
    if (item.type === NotificationType.Team) {
      return `${this.$t('notifications.team')}`;
    }
    if (item.type === NotificationType.TeamDelete) {
      return `${this.$t('notifications.team')}`;
    }
    if (item.type === NotificationType.SubscriptionSeat) {
      return '';
    }
    return '';
  }

  getUserName(activity: NotificationActivity): string {
    return activity.userDisplayName;
  }

  getUserAvatarPath({ userAvatarPath, id }: NotificationActivity): Avatar {
    return { userAvatarPath, id };
  }

  formatDate(activity: NotificationActivity): string {
    const roundingDefault = moment.relativeTimeRounding();
    const mCreatedAt = moment(activity.createdAt).locale(
      `${this.$t('notifications.language')}`
    );
    const diffFromNowInADay = moment()
      .locale(`${this.$t('notifications.language')}`)
      .diff(mCreatedAt, 'days', true);
    if (diffFromNowInADay < 1) {
      moment.relativeTimeRounding(Math.floor);
      const createdAtFromNow = mCreatedAt.fromNow();
      moment.relativeTimeRounding(roundingDefault);
      return createdAtFromNow;
    }
    const formatter = function(e: string) {
      return mCreatedAt.format(e);
    };
    const dateStringFormat = diffFromNowInADay < 7 ? 'ddd DD' : 'DD MMM';
    const dateString = formatter(dateStringFormat);
    const timeString = formatter('HH:mm');
    const fullDateTime = `${dateString} ${timeString}`;
    return fullDateTime;
  }

  isUnread(item: NotificationItem) {
    return item.unread;
  }
}
