<template>
  <div class="nav-profile text-sm">
    <base-dropdown position="bottom-right" sm-right>
      <button
        slot="toggle"
        data-testid="profile-btn"
        class="block border-2 w-10 h-10 rounded-full overflow-hidden boder-gray-600 focus:outline-none focus:border-white"
      >
        <img
          data-testid="icon-profile-personal"
          class="w-full h-full object-cover"
          :src="profileImg"
        />
      </button>
      <base-dropdown-item
        keep
        :hoverable="false"
        class="flex justify-center items-center border-b border-gray-200"
      >
        <img
          class="w-16 h-16 object-cover rounded-md flex-shrink-0"
          :src="profileImg"
        />
        <div class="overflow-hidden leading-loose flex-1 px-2">
          <p class="text-gray-800 font-semibold text-base">
            {{ profile.name }}
          </p>
          <p class="text-gray-600 truncate">{{ profile.email }}</p>
        </div>
      </base-dropdown-item>
      <div class="base-dropdown__separator" />
      <base-dropdown-item
        v-if="hideSubscription"
        :hoverable="false"
        keep
        class="flex items-center"
      >
        <base-icon size="18" class="pl-px pt-1">
          <icon-menu-subscription />
        </base-icon>
        <div class="text-sm">
          Subscription 👑
        </div>
        <div class="text-gray-600 ml-auto">
          Active
        </div>
      </base-dropdown-item>
      <base-dropdown-item
        class="flex items-center"
        data-testid="profile-settings-btn"
        @click.prevent="profileClick(0)"
        ><base-icon size="18" class="mr-2">
          <icon-setting />
        </base-icon>
        {{ $t('nav.profile_settings') }}</base-dropdown-item
      >
      <base-dropdown-item data-testid="change-language-btn" :keep="true">
        <base-dropdown position="bottom-right" width="small">
          <div slot="toggle" class="flex items-center">
            <base-icon size="18">
              <icon-language />
            </base-icon>
            <div class="flex-1">
              {{ $t('nav.language') }}
            </div>
            <div class="text-gray-600">
              {{ $t('language') }}
            </div>
          </div>
          <base-dropdown-item
            keep
            :hoverable="false"
            class="flex justify-center items-center border-b border-gray-200"
          >
            <div class="overflow-hidden flex-1 px-2">
              {{ $t('nav.change_language') }}
            </div>
          </base-dropdown-item>
          <base-dropdown-item
            data-testid="choose-language-english"
            @click.prevent="changeLanguage('en')"
          >
            <div class="flex">
              <div class="flex-1">English</div>
              <div v-if="$i18n.locale == 'en'" class="flex items-center">
                <base-icon size="16">
                  <icon-check />
                </base-icon>
              </div></div
          ></base-dropdown-item>
          <base-dropdown-item
            data-testid="choose-language-thai"
            @click.prevent="changeLanguage('th')"
            ><div class="flex">
              <div class="flex-1">ภาษาไทย</div>
              <div v-if="$i18n.locale == 'th'" class="flex items-center">
                <base-icon size="16">
                  <icon-check />
                </base-icon>
              </div></div
          ></base-dropdown-item>
        </base-dropdown>
      </base-dropdown-item>
      <base-dropdown-item
        class="flex items-center"
        data-testid="notification-setting-btn"
        @click.prevent="profileClick(2)"
        ><base-icon size="18" class="mr-2"> <icon-notification /> </base-icon
        >{{ $t('nav.notifications') }}</base-dropdown-item
      >
      <base-dropdown-item
        data-testid="trash-setting-btn"
        class="border-b border-gray-200 flex items-center"
        @click.prevent="profileClick(3)"
        ><base-icon size="18" class="mr-2"> <icon-trash-border /> </base-icon
        >{{ $t('nav.trash') }}</base-dropdown-item
      >
      <div class="base-dropdown__separator" />
      <base-dropdown-item
        v-if="hideSubscription && !profile.canFreeTrial"
        class="flex items-center p-0 border-b border-gray-200"
        @click="workSpaceClick()"
      >
        <base-icon size="18" class="mt-auto pl-px">
          <icon-workspace />
        </base-icon>
        <div>
          Workspace Management
        </div>
        <div class="chip-beta">
          <span> Beta </span>
        </div>
        <base-icon size="16" class="ml-auto">
          <icon-chevron-right />
        </base-icon>
      </base-dropdown-item>
      <div class="base-dropdown__separator" />
      <base-dropdown-item
        data-testid="more-btn"
        keep
        :hoverable="false"
        class="p-0 border-b border-gray-200"
      >
        <base-collapse collapsed default-collapsed>
          <template #collapser="{ isCollapsed, toggle }">
            <div
              class="flex justify-between items-center cursor-pointer"
              :class="{ 'mb-2': !isCollapsed }"
              @click="toggle"
            >
              <span>{{ $t('nav.more') }}</span>
              <base-icon name="menu-more" size="16">
                <icon-chevron-right v-if="isCollapsed" />
                <icon-chevron-down v-else />
              </base-icon>
            </div>
          </template>
          <base-dropdown-item
            data-testid="term-and-condition-btn"
            class="-mx-4"
          >
            <a
              :href="`/${$i18n.locale}/terms?from=profile`"
              rel="noopener noreferrer"
              target="__blank"
              class="flex items-center"
            >
              <base-icon size="18" class="mr-1">
                <icon-terms />
              </base-icon>
              {{ $t('nav.conditions') }}
              <!-- <fa-icon size="xs" icon="external-link-alt" /> -->
            </a>
          </base-dropdown-item>
          <base-dropdown-item
            data-testid="feedback-btn"
            class="-mx-4 flex items-center"
            @click="openFeedbackForm"
            ><base-icon size="18" class="mr-2"> <icon-feedback /> </base-icon
            >{{ $t('nav.feedback') }}</base-dropdown-item
          >
        </base-collapse>
      </base-dropdown-item>
      <div class="base-dropdown__separator" />

      <base-dropdown-item
        data-testid="logout-btn"
        class="border-b border-gray-200 flex items-center"
        @click.prevent="logout"
        ><base-icon size="18" class="mr-2"> <icon-logout /> </base-icon
        >{{ $t('nav.logout') }}</base-dropdown-item
      >

      <base-dropdown-item
        data-testid="show-version-and-last-update"
        keep
        :hoverable="false"
        class="text-xs"
      >
        <div class="pt-1">
          <div class="text-gray-700">
            {{ $t('nav.version') }} {{ version.number }}
          </div>
          <div class="text-gray-500">
            {{ $t('nav.latest_update') }}: {{ version.latestUpdate }}
          </div>
        </div>
      </base-dropdown-item>
    </base-dropdown>

    <modal-profile ref="modalProfile" />
  </div>
</template>

<script>
// import IconHamberger from '@/components/icons/IconHamberger';
import IconChevronRight from '@/components/icons/IconChevronRight';
import IconChevronDown from '@/components/icons/IconChevronDown';
import IconSetting from '@/components/icons/IconSetting';
import IconLanguage from '@/components/icons/IconLanguage';
import IconCheck from '@/components/icons/IconCheck';
import IconNotification from '@/components/icons/IconNotification';
import IconTrashBorder from '@/components/icons/IconTrashBorder';
import IconLogout from '@/components/icons/IconLogout';
import IconTerms from '@/components/icons/IconTerms';
import IconFeedback from '@/components/icons/IconFeedback';
import IconMenuSubscription from '@/components/icons/IconMenuSubscription';
import IconWorkspace from '@/components/icons/IconWorkspace';
import ModalProfile from '@/components/modal-profile';
import { profilesApi } from '@/services/apis';
import Locale from '@/storages/locale';

export default {
  components: {
    // IconHamberger,
    IconChevronRight,
    IconChevronDown,
    IconSetting,
    IconLanguage,
    IconCheck,
    IconNotification,
    IconTrashBorder,
    IconLogout,
    IconTerms,
    IconFeedback,
    IconMenuSubscription,
    IconWorkspace,
    ModalProfile
  },
  data() {
    return {
      showDropDown: false
    };
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
      } catch (error) {
        this.error = error?.response?.data?.message;
      }
      window.location.replace(`/${this.$i18n.locale}`);
    },
    hide() {
      this.showDropDown = false;
    },
    profileClick(tabIndex) {
      this.$refs.modalProfile.show(tabIndex);
    },
    openFeedbackForm() {
      this.$modal.show('feedback');
    },
    async changeLanguage(locale) {
      await profilesApi.updateProfile({ language: locale });
      await this.$store.dispatch('auth/fetchMe');
      const { query } = this.$route;
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({
          params: { locale },
          query
        });
        this.$router.push(to.location);
      }
      Locale.setLocale(locale);
    },
    openReport() {
      this.$modal.show('personal-report');
    },
    workSpaceClick() {
      this.$router.push({
        name: 'Subscription-Overview'
      });
    }
  },
  mounted() {
    this.popupItem = this.$el;
  },
  computed: {
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    profileImg() {
      return (
        this.profile?.profilePicture ||
        'https://www.pngarts.com/files/5/User-Avatar-PNG-Image.png'
      );
    },
    version() {
      return this.$store.getters['version/getVersion'];
    },

    locale() {
      return this.$i18n.locale;
    },
    hideSubscription() {
      return (
        !this.profile.subscriptionExempted &&
        this.profile.haveActiveSubscription
      );
    }
  }
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chip-beta {
  @apply text-xs ml-2;
  border: 1px solid #168ae2;
  color: #168ae2;
  border-radius: 28px;
  padding: 0px 8px;
}
</style>
