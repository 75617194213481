<template>
  <path
    d="M9.05554 1.76391H4.39568L3.56589 0.934118C3.52081 0.88891 3.46724 0.853056 3.40826 0.828613C3.34928 0.80417 3.28605 0.791621 3.22221 0.791687H1.27776C0.741584 0.791687 0.305542 1.22773 0.305542 1.76391V8.56947C0.305542 9.10565 0.741584 9.54169 1.27776 9.54169H9.05554C9.59172 9.54169 10.0278 9.10565 10.0278 8.56947V2.73613C10.0278 2.19995 9.59172 1.76391 9.05554 1.76391Z"
    :fill="fill"
  />
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#27AE60'
    }
  }
};
</script>
