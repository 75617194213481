<template>
  <modal adaptive scrollable height="493" width="800" name="delete-account">
    <div class="flex">
      <div class="flex-grow text-2xl px-8 py-2">
        {{ $t('account.delete.button') }}
      </div>
      <div class="flex-grow-0 px-8 py-3">
        <div @click="close">
          <base-icon class="pointer" icon-size="18" wide="p-0" name="close">
            <icon-close />
          </base-icon>
        </div>
      </div>
    </div>
    <div class="flex border-b-1 pb-1" />
    <div class="flex-grow px-8 py-3">
      <div class="flex-grow mt-2 mb-3">
        <div class="text-lg mb-1">
          {{ $t('account.delete.confrim.sure') }}
        </div>
        <span class="text-gray-600 text-sm">
          {{ $t('account.delete.confrim.reason') }}
        </span>
      </div>
      <div class="flex flex-wrap">
        <div>
          <button
            v-for="item in items"
            :key="item.message"
            class=" bg-gray-200 hover:bg-brand-blue  hover:text-white rounded-full pt-1
            pb-1 px-3 mr-3 mb-3"
            :data-testid="`${item.message}-btn`"
            :class="{
              'bg-brand-blue text-white': item.message === reason
            }"
            @click="say(item.message)"
          >
            <span class="text-sm ">{{ item.message }}</span>
          </button>
        </div>
      </div>

      <div v-if="improve === true" class="flex-grow mt-2 mb-3">
        <div class="text-base mb-1 my-3">
          {{ $t('account.delete.confrim.suggest') }}
        </div>
        <label>
          <textarea
            v-model="suggestion"
            data-testid="description-delete-account-textarea"
            class="base-input base-input--textarea"
            rows="4"
            :placeholder="$t('account.delete.confrim.placeholder')"
            type="textarea"
          />
        </label>
      </div>

      <div class="flex justify-end mr-6 pb-8 absolute inset-x-0 bottom-0">
        <base-button
          color="transparent"
          wide="px-10"
          data-testid="cancel-btn"
          @click="close"
        >
          {{ $t('account.cancel') }}
        </base-button>
        <base-button
          wide="px-10"
          :color="isCanSubmit ? 'red' : 'gray2'"
          :disabled="!isCanSubmit"
          type="submit"
          data-testid="delete-btn"
          @click="confrimDelete"
          >{{ $t('account.delete.button') }}</base-button
        >
      </div>
    </div>
  </modal>
</template>

<script>
import apis from '@/services/apis';
import IconClose from '@/components/icons/IconClose';

export default {
  components: {
    IconClose
  },
  data() {
    return {
      suggestion: '',
      reason: '',
      isCanSubmit: false,
      improve: false,
      items: [
        { message: this.$t('account.delete.confrim.reasons.donlike') },
        { message: this.$t('account.delete.confrim.reasons.broken') },
        { message: this.$t('account.delete.confrim.reasons.break') },
        { message: this.$t('account.delete.confrim.reasons.account') },
        { message: this.$t('account.delete.confrim.reasons.other') }
      ]
    };
  },

  methods: {
    say(message) {
      this.improve = true;
      this.reason = message;
      this.isCanSubmit = true;
    },
    close() {
      this.suggestion = '';
      this.isCanSubmit = false;
      this.reason = '';
      this.improve = false;
      this.$modal.hide('delete-account');
    },
    async confrimDelete() {
      const res = apis.delete('/me/deactivate', {
        data: {
          reason: this.reason,
          suggestion: this.suggestion
        }
      });
      try {
        await this.$store.dispatch('auth/logout');
      } catch (error) {
        this.error = error?.response?.data?.message;
      }
      window.location.replace(`/${this.$i18n.locale}`);
      return res;
    }
  }
};
</script>

<style scope>
button:disabled,
form[disabled='disabled'] button,
.bt:disabled {
  background: #f2f3f4;
  color: #333333;
}
</style>
